// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.jsx");
  import.meta.hot.lastModified = "1718862545737.2957";
}
// REMIX HMR END

import { Links, Meta, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";

// const livechatscript = `
//  window.$crisp=[];
//  window.CRISP_WEBSITE_ID="f2fbf04e-52f0-4818-83ec-ecd33dc5e388";
//  ( function(){  
//   var d=document;
//   var s=d.createElement("script");
//  s.src="https://client.crisp.chat/l.js";
//  s.async=1;
//  d.getElementsByTagName("head")[0].appendChild(s);
//  })();
// `;

export default function App() {
  return <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <title>ZOX Delivery Check</title>
        {/* <script type="text/javascript"  dangerouslySetInnerHTML={{ __html: livechatscript } } />  */}
        <script src="https://chatbox.simplebase.co/projects/6654231a4137fd7c6265b172/widget.js"></script>
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        
        <Scripts />
      </body>
    </html>;
}
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;